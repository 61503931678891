<template>
  <vx-card>
    <vs-row vs-w="12">
      <vs-col vs-w="11">
        <h4 style="color: #b4aa99">Parâmetros Predefinidos</h4>
      </vs-col>
      <vs-col vs-w="1" vs-type="flex" vs-justify="flex-end" vs-align="center">
        <vs-button
          :disabled="!$atividades.permissoes.includes(14) || blockButton"
          size="small"
          color="success"
          type="relief"
          @click="update"
          >Atualizar</vs-button
        >
      </vs-col>
    </vs-row>
    <VuePerfectScrollbar
      class="scroll-parametros"
      :settings="settings"
      style="background-color: transparent"
    >
      <div id="parametros" class="mt-10 vs-con-loading__container">
        <vs-divider border-style="dotted" class="font-bold" color="dark"
          >Configuração Comercial</vs-divider
        >
        <vs-row
          vs-type="flex"
          vs-w="12"
          vs-align="center"
          vs-justify="flex-start"
        >
          <vs-col
            vs-type="flex"
            vs-align="center"
            vs-justify="flex-start"
            vs-w="2.4"
            class="mb-3"
          >
            <vs-input
              min="0"
              type="number"
              class="mx-4 w-full"
              icon="DIAS"
              icon-after="true"
              color="dark"
              label-placeholder="Validade da Proposta"
              v-model="parametro.validade_proposta"
            />
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-align="center"
            vs-justify="center"
            vs-w="2.4"
            class="mb-3"
          >
            <vs-input
              type="number"
              class="w-full mx-4"
              label-placeholder="Serviço Complementar"
              color="dark"
              min="0"
              icon="DIAS"
              icon-after="true"
              v-model="parametro.dias_servico_complementar"
            />
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-align="center"
            vs-justify="center"
            vs-w="2.4"
            class="mb-3"
          >
            <vs-input
              type="number"
              class="w-full mx-4"
              label-placeholder="Carros da Empresa"
              color="dark"
              min="0"
              icon="QTD"
              icon-after="true"
              v-model="parametro.carros_empresa"
            />
          </vs-col>
          <vs-col vs-w="2" class="mb-3">
            <vs-checkbox
              color="success"
              v-model="parametro.usarHorario13ParaTurnoTarde"
              class="w-full mx-4 pt-6"
              >Horario das 13 no turno da tarde</vs-checkbox
            >
          </vs-col>
        </vs-row>
        <vs-divider border-style="dotted" class="font-bold" color="dark"
          >Configuração Financeiro</vs-divider
        >
        <vs-row vs-type="flex" vs-w="12">
          <vs-col
            vs-type="flex"
            vs-align="center"
            vs-justify="center"
            vs-w="2.4"
            class="mb-3"
          >
            <vs-input
              type="text"
              class="mx-4 w-full"
              label-placeholder="INSS"
              color="dark"
              min="0"
              icon="%"
              icon-after="true"
              v-model="parametro.inss"
            />
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-align="center"
            vs-justify="center"
            vs-w="2.4"
            class="mb-3"
          >
            <vs-input
              class="mx-4 w-full"
              type="text"
              label-placeholder="ISS"
              color="dark"
              min="0"
              icon="%"
              icon-after="true"
              v-model="parametro.iss"
            />
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-align="center"
            vs-justify="center"
            vs-w="2.4"
            class="mb-3"
          >
            <vs-input
              class="w-full mx-4"
              min="0"
              type="number"
              icon="DIAS"
              icon-after="true"
              color="dark"
              v-model="parametro.dias_cobranca"
              label-placeholder="Cobrança"
            />
          </vs-col>
          <vs-col vs-w="2" class="mb-3">
            <vs-checkbox
              color="success"
              v-model="parametro.auto_boleto"
              class="w-full mx-4 pt-6"
              >Boleto Automático</vs-checkbox
            >
          </vs-col>
          <vs-col vs-w="2" class="mb-3">
            <vs-checkbox
              color="success"
              v-model="parametro.auto_nota"
              class="w-full mx-4 pt-6"
              >Nota Automática</vs-checkbox
            >
          </vs-col>
        </vs-row>
        <vs-row vs-type="flex" vs-w="12">
          <vs-col
            vs-type="flex"
            vs-align="center"
            vs-justify="center"
            vs-w="3"
            class="mb-3"
          >
            <div class="w-full">
              <vs-col
                vs-type="flex"
                vs-align="center"
                vs-justify="center"
                vs-w="12"
                class="mb-3"
              >
                <vs-input
                  type="email"
                  class="w-full mx-4"
                  color="dark"
                  label-placeholder="Email Faturamento"
                  v-model="parametro.email_faturamento"
                />
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-align="center"
                vs-justify="center"
                vs-w="12"
                class="mb-3"
              >
                <vs-input
                  type="password"
                  class="w-full mx-4"
                  color="dark"
                  label-placeholder="Senha"
                  v-model="parametro.password_faturamento"
                />
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-align="center"
                vs-justify="center"
                vs-w="12"
                class="mb-3"
              >
                <vs-input
                  type="text"
                  class="w-full mx-4"
                  color="dark"
                  label-placeholder="Email Corpo Email"
                  v-model="parametro.email_faturamento_contato"
                />
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-align="center"
                vs-justify="center"
                vs-w="12"
                class="mb-3"
              >
                <vs-input
                  type="text"
                  class="w-full mx-4"
                  color="dark"
                  label-placeholder="Telefone Corpo Email"
                  v-model="parametro.telefone_faturamento_contato"
                />
              </vs-col>
            </div>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-align="center"
            vs-justify="center"
            vs-w="3"
            class="mb-3"
          >
            <div class="w-full">
              <vs-col
                vs-type="flex"
                vs-align="center"
                vs-justify="center"
                vs-w="12"
                class="mb-3"
              >
                <vs-input
                  type="email"
                  class="w-full mx-4"
                  color="dark"
                  label-placeholder="Email Cobrança"
                  v-model="parametro.email_cobranca"
                />
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-align="center"
                vs-justify="center"
                vs-w="12"
                class="mb-3"
              >
                <vs-input
                  type="password"
                  class="w-full mx-4"
                  color="dark"
                  label-placeholder="Senha"
                  v-model="parametro.password_cobranca"
                />
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-align="center"
                vs-justify="center"
                vs-w="12"
                class="mb-3"
              >
                <vs-input
                  type="text"
                  class="w-full mx-4"
                  color="dark"
                  label-placeholder="Email Corpo Email"
                  v-model="parametro.email_cobranca_contato"
                />
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-align="center"
                vs-justify="center"
                vs-w="12"
                class="mb-3"
              >
                <vs-input
                  type="text"
                  class="w-full mx-4"
                  color="dark"
                  label-placeholder="Telefone Corpo Email"
                  v-model="parametro.telefone_cobranca_contato"
                />
              </vs-col>
            </div>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-align="center"
            vs-justify="center"
            vs-w="3"
            class="mb-3"
          >
            <div class="w-full">
              <vs-col
                vs-type="flex"
                vs-align="center"
                vs-justify="center"
                vs-w="12"
                class="mb-3"
              >
                <vs-input
                  type="email"
                  class="w-full mx-4"
                  color="dark"
                  label-placeholder="Email Lembrete Vencimento"
                  v-model="parametro.email_lembrete_vencimento"
                />
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-align="center"
                vs-justify="center"
                vs-w="12"
                class="mb-3"
              >
                <vs-input
                  type="password"
                  class="w-full mx-4"
                  color="dark"
                  label-placeholder="Senha"
                  v-model="parametro.password_lembrete_vencimento"
                />
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-align="center"
                vs-justify="center"
                vs-w="12"
                class="mb-3"
              >
                <vs-input
                  type="text"
                  class="w-full mx-4"
                  color="dark"
                  label-placeholder="Email Corpo Email"
                  v-model="parametro.email_lembrete_vencimento_contato"
                />
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-align="center"
                vs-justify="center"
                vs-w="12"
                class="mb-3"
              >
                <vs-input
                  type="text"
                  class="w-full mx-4"
                  color="dark"
                  label-placeholder="Telefone Corpo Email"
                  v-model="parametro.telefone_lembrete_vencimento_contato"
                />
              </vs-col>
            </div>
          </vs-col>
        </vs-row>
        <vs-divider border-style="dotted" class="font-bold" color="dark"
          >Configuração Processual</vs-divider
        >
        <vs-row
          vs-align="flex-start"
          vs-justify="flex-start"
          vs-type="flex"
          vs-w="12"
        >
          <vs-col
            vs-type="flex"
            vs-align="center"
            vs-justify="center"
            vs-w="3"
            class="mb-3"
          >
            <vs-input
              class="w-full mx-4"
              label-placeholder="Técnico Responsável"
              type="text"
              color="dark"
              v-model="parametro.tecnico_responsavel"
            />
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-align="center"
            vs-justify="center"
            vs-w="3"
            class="mb-3"
          >
            <vs-input
              class="w-full mx-4"
              label-placeholder="Engenheiro Químico"
              type="text"
              color="dark"
              v-model="parametro.enginheiro_quimico"
            />
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-align="center"
            vs-justify="center"
            vs-w="3"
            class="mb-3"
          >
            <vs-input
              class="w-full mx-4"
              color="dark"
              label-placeholder="Telefone de Emergência"
              v-mask="'(##)#########'"
              v-model="parametro.telefone_de_emergencia"
            />
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-align="center"
            vs-justify="center"
            vs-w="3"
            class="mb-3"
          >
            <vs-checkbox
              class="w-full mx-4 mt-6"
              color="success"
              v-model="parametro.impressao_certificado"
              >Certificado Pré-Impresso</vs-checkbox
            >
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-align="center"
            vs-justify="center"
            vs-w="3"
            class="mb-3"
          >
            <div class="w-full">
              <vs-col
                vs-type="flex"
                vs-align="center"
                vs-justify="center"
                vs-w="12"
                class="mb-3"
              >
                <vs-input
                  type="text"
                  class="w-full mx-4"
                  color="dark"
                  label-placeholder="Assinatura Relatório"
                  v-model="parametro.assinatura_relatorio"
                />
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-align="center"
                vs-justify="center"
                vs-w="12"
                class="mb-3"
              >
                <vs-input
                  type="text"
                  class="w-full mx-4"
                  color="dark"
                  label-placeholder="Assinatura Certificado"
                  v-model="parametro.assinatura_certificado"
                />
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-align="center"
                vs-justify="center"
                vs-w="12"
                class="mb-3"
              >
                <vs-input
                  type="text"
                  class="w-full mx-4"
                  color="dark"
                  label-placeholder="Número Registro (RNP)"
                  v-model="parametro.numero_registro"
                />
              </vs-col>
            </div>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-align="center"
            vs-justify="center"
            vs-w="3"
            class="mb-3"
          >
            <div class="w-full">
              <vs-col
                vs-type="flex"
                vs-align="center"
                vs-justify="center"
                vs-w="12"
                class="mb-3"
              >
                <vs-input
                  type="email"
                  class="w-full mx-4"
                  color="dark"
                  label-placeholder="Email Confirmação de Serviços"
                  v-model="parametro.email_confirmacao_servico"
                />
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-align="center"
                vs-justify="center"
                vs-w="12"
                class="mb-3"
              >
                <vs-input
                  type="password"
                  class="w-full mx-4"
                  color="dark"
                  label-placeholder="Senha"
                  v-model="parametro.password_confirmacao_servico"
                />
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-align="center"
                vs-justify="center"
                vs-w="12"
                class="mb-3"
              >
                <vs-input
                  type="text"
                  class="w-full mx-4"
                  color="dark"
                  label-placeholder="Email Corpo Email"
                  v-model="parametro.email_confirmacao_servico_contato"
                />
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-align="center"
                vs-justify="center"
                vs-w="12"
                class="mb-3"
              >
                <vs-input
                  type="text"
                  class="w-full mx-4"
                  color="dark"
                  label-placeholder="Telefone Corpo Email"
                  v-model="parametro.telefone_confirmacao_servico_contato"
                />
              </vs-col>
            </div>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-align="center"
            vs-justify="center"
            vs-w="3"
            class="mb-3"
          >
            <div class="w-full">
              <vs-col
                vs-type="flex"
                vs-align="center"
                vs-justify="center"
                vs-w="12"
                class="mb-3"
              >
                <vs-input
                  type="email"
                  class="w-full mx-4"
                  color="dark"
                  label-placeholder="Email Relatório Técnico"
                  v-model="parametro.email_rel_tecnico"
                />
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-align="center"
                vs-justify="center"
                vs-w="12"
                class="mb-3"
              >
                <vs-input
                  class="w-full mx-4"
                  color="dark"
                  label-placeholder="Senha"
                  type="password"
                  v-model="parametro.password_rel_tecnico"
                />
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-align="center"
                vs-justify="center"
                vs-w="12"
                class="mb-3"
              >
                <vs-input
                  type="text"
                  class="w-full mx-4"
                  color="dark"
                  label-placeholder="Email Corpo Email"
                  v-model="parametro.email_rel_tecnico_contato"
                />
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-align="center"
                vs-justify="center"
                vs-w="12"
                class="mb-3"
              >
                <vs-input
                  type="text"
                  class="w-full mx-4"
                  color="dark"
                  label-placeholder="Telefone Corpo Email"
                  v-model="parametro.telefone_rel_tecnico_contato"
                />
              </vs-col>
            </div>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-align="center"
            vs-justify="center"
            vs-w="3"
            class="mb-3"
          >
            <div class="w-full">
              <vs-col
                vs-type="flex"
                vs-align="center"
                vs-justify="center"
                vs-w="12"
                class="mb-3"
              >
                <vs-input
                  type="email"
                  class="w-full mx-4"
                  color="dark"
                  label-placeholder="Local do Servidor para Upload"
                  v-model="parametro.storage_url"
                />
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-align="center"
                vs-justify="center"
                vs-w="12"
                class="mb-3"
              >
                <vs-input
                  class="w-full mx-4"
                  color="dark"
                  label-placeholder="Local do Servidor para Visualização"
                  type="text"
                  v-model="parametro.storage_cdn_url"
                />
              </vs-col>
            </div>
          </vs-col>
        </vs-row>
        <vs-row
          vs-type="flex"
          vs-align="flex-start"
          vs-justify="flex-start"
          vs-w="12"
        >
          <vs-col
            vs-type="flex"
            vs-align="center"
            vs-justify="center"
            vs-w="2"
            class="mb-3"
          >
            <vs-input
              type="text"
              class="w-full mx-4"
              color="dark"
              icon="DIAS"
              icon-after="true"
              label-placeholder="Comissão 1 Estrela"
              v-model="parametro.comissao_1_estrela"
            />
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-align="center"
            vs-justify="center"
            vs-w="2"
            class="mb-3"
          >
            <vs-input
              type="text"
              class="w-full mx-4"
              color="dark"
              icon="R$"
              icon-after="true"
              label-placeholder="Comissão 2 estrelas"
              v-model="parametro.comissao_2_estrela"
            />
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-align="center"
            vs-justify="center"
            vs-w="2"
            class="mb-3"
          >
            <vs-input
              type="text"
              class="w-full mx-4"
              icon="R$"
              icon-after="true"
              color="dark"
              label-placeholder="Comissão 3 estrelas"
              v-model="parametro.comissao_3_estrela"
            />
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-align="center"
            vs-justify="center"
            vs-w="2"
            class="mb-3"
          >
            <vs-input
              type="text"
              class="w-full mx-4"
              color="dark"
              icon="R$"
              icon-after="true"
              label-placeholder="Comissão 4 estrelas"
              v-model="parametro.comissao_4_estrela"
            />
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-align="center"
            vs-justify="center"
            vs-w="2"
            class="mb-3"
          >
            <vs-input
              type="text"
              class="w-full mx-4"
              color="dark"
              icon="R$"
              icon-after="true"
              label-placeholder="Comissão 5 estrelas"
              v-model="parametro.comissao_5_estrela"
            />
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-align="center"
            vs-justify="center"
            vs-w="2"
            class="mb-3"
          >
            <vs-input
              type="text"
              class="w-full mx-4"
              icon="R$"
              icon-after="true"
              color="dark"
              label-placeholder="Comissão não avaliado"
              v-model="parametro.comissao_nao_avaliado"
            />
          </vs-col>
        </vs-row>

        <vs-row vs-type="flex" vs-align="center" vs-justify="center" vs-w="12">
          <vs-col
            vs-type="flex"
            vs-align="center"
            vs-justify="center"
            vs-w="2"
            class="mb-3"
          >
            <vs-input
              type="text"
              class="w-full mx-4"
              color="dark"
              icon="QTD"
              icon-after="true"
              label-placeholder="Carros manha"
              v-model="parametro.carros_manha"
            />
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-align="center"
            vs-justify="center"
            vs-w="2"
            class="mb-3"
          >
            <vs-input
              type="text"
              class="w-full mx-4"
              color="dark"
              icon="QTD"
              icon-after="true"
              label-placeholder="Carros almoco"
              v-model="parametro.carros_almoco"
            />
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-align="center"
            vs-justify="center"
            vs-w="2"
            class="mb-3"
          >
            <vs-input
              type="text"
              class="w-full mx-4"
              color="dark"
              icon="QTD"
              icon-after="true"
              label-placeholder="Carros tarde"
              v-model="parametro.carros_tarde"
            />
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-align="center"
            vs-justify="center"
            vs-w="2"
            class="mb-3"
          >
            <vs-input
              type="text"
              class="w-full mx-4"
              color="dark"
              icon="QTD"
              icon-after="true"
              label-placeholder="Carros noite"
              v-model="parametro.carros_noite"
            />
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-align="center"
            vs-justify="center"
            vs-w="2"
            class="mb-3"
          >
            <vs-input
              type="text"
              class="w-full mx-4"
              color="dark"
              icon="QTD"
              icon-after="true"
              label-placeholder="Carros madrugada"
              v-model="parametro.carros_madrugada"
            />
          </vs-col>
        </vs-row>

        <vs-row vs-type="flex" vs-align="center" vs-justify="center" vs-w="12">
          <vs-col
            vs-type="flex"
            vs-align="center"
            vs-justify="center"
            vs-w="2"
            class="mb-3"
          >
            <vs-input
              type="text"
              class="w-full mx-4"
              color="dark"
              icon="QTD"
              icon-after="true"
              label-placeholder="Motos manha"
              v-model="parametro.motos_manha"
            />
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-align="center"
            vs-justify="center"
            vs-w="2"
            class="mb-3"
          >
            <vs-input
              type="text"
              class="w-full mx-4"
              color="dark"
              icon="QTD"
              icon-after="true"
              label-placeholder="Motos almoco"
              v-model="parametro.motos_almoco"
            />
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-align="center"
            vs-justify="center"
            vs-w="2"
            class="mb-3"
          >
            <vs-input
              type="text"
              class="w-full mx-4"
              color="dark"
              icon="QTD"
              icon-after="true"
              label-placeholder="Motos tarde"
              v-model="parametro.motos_tarde"
            />
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-align="center"
            vs-justify="center"
            vs-w="2"
            class="mb-3"
          >
            <vs-input
              type="text"
              class="w-full mx-4"
              color="dark"
              icon="QTD"
              icon-after="true"
              label-placeholder="Motos noite"
              v-model="parametro.motos_noite"
            />
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-align="center"
            vs-justify="center"
            vs-w="2"
            class="mb-3"
          >
            <vs-input
              type="text"
              class="w-full mx-4"
              color="dark"
              icon="QTD"
              icon-after="true"
              label-placeholder="Motos madrugada"
              v-model="parametro.motos_madrugada"
            />
          </vs-col>
        </vs-row>
        <vs-divider border-style="dotted" color="dark" class="font-bold"
          >Selos do Relatório Técnico (Limite de 4 Selos)</vs-divider
        >
        <vs-row vs-type="flex" vs-w="12" vs-justify="flex-start">
          <vs-col vs-w="4" vs-type="flex" vs-align="center" vs-justify="center">
            <div class="my-10">
              <el-upload
                accept=" .jpg, .jpeg, .png, .gif"
                action=""
                :auto-upload="false"
                ref="selo_1"
                :on-change="changeListSelos"
                :file-list="fileListSelo1"
                :on-remove="removeSelo1"
                class="w-full"
                :on-exceed="exceed"
                :key="reRender"
                :limit="1"
                list-type="picture-card"
              >
                <i class="el-icon-plus" style="font-size: 18px">Selo 1</i>
              </el-upload>
            </div>
          </vs-col>
          <vs-col vs-w="4" vs-type="flex" vs-align="center" vs-justify="center">
            <div class="my-10">
              <el-upload
                accept=" .jpg, .jpeg, .png, .gif"
                action=""
                :auto-upload="false"
                ref="selo_2"
                :on-change="changeListSelos"
                :file-list="fileListSelo2"
                :on-remove="removeSelo2"
                class="w-full"
                :on-exceed="exceed"
                :key="reRender"
                :limit="1"
                list-type="picture-card"
              >
                <i class="el-icon-plus" style="font-size: 18px">Selo 2</i>
              </el-upload>
            </div>
          </vs-col>
          <vs-col vs-w="4" vs-type="flex" vs-align="center" vs-justify="center">
            <div class="my-10">
              <el-upload
                accept=" .jpg, .jpeg, .png, .gif"
                action=""
                :auto-upload="false"
                ref="selo_3"
                :on-change="changeListSelos"
                :file-list="fileListSelo3"
                :on-remove="removeSelo3"
                class="w-full"
                :on-exceed="exceed"
                :key="reRender"
                :limit="1"
                list-type="picture-card"
              >
                <i class="el-icon-plus" style="font-size: 18px">Selo 3</i>
              </el-upload>
            </div>
          </vs-col>
          <vs-col vs-w="4" vs-type="flex" vs-align="center" vs-justify="center">
            <div class="my-10">
              <el-upload
                accept=" .jpg, .jpeg, .png, .gif"
                action=""
                :auto-upload="false"
                ref="selo_4"
                :on-change="changeListSelos"
                :file-list="fileListSelo4"
                :on-remove="removeSelo4"
                class="w-full"
                :on-exceed="exceed"
                :key="reRender"
                :limit="1"
                list-type="picture-card"
              >
                <i class="el-icon-plus" style="font-size: 18px">Selo 4</i>
              </el-upload>
            </div>
          </vs-col>
        </vs-row>
        <vs-divider border-style="dotted" color="dark" class="font-bold"
          >Selos do Cerficado de Serviços (Limite de 4 Selos)</vs-divider
        >
        <vs-row vs-type="flex" vs-w="12" vs-justify="flex-start">
          <vs-col vs-w="4" vs-type="flex" vs-align="center" vs-justify="center">
            <div class="my-10">
              <el-upload
                accept=" .jpg, .jpeg, .png, .gif"
                action=""
                :auto-upload="false"
                ref="selo_certificado_1"
                :on-change="changeListSelosCerficado"
                :file-list="fileListSeloCerficado1"
                :on-remove="removeSeloCerficado1"
                class="w-full"
                :on-exceed="exceed"
                :key="reRender"
                :limit="1"
                list-type="picture-card"
              >
                <i class="el-icon-plus" style="font-size: 18px"
                  >Selo Cerficado 1</i
                >
              </el-upload>
            </div>
          </vs-col>
          <vs-col vs-w="4" vs-type="flex" vs-align="center" vs-justify="center">
            <div class="my-10">
              <el-upload
                accept=" .jpg, .jpeg, .png, .gif"
                action=""
                :auto-upload="false"
                ref="selo_certificado_2"
                :on-change="changeListSelosCerficado"
                :file-list="fileListSeloCerficado2"
                :on-remove="removeSeloCerficado2"
                class="w-full"
                :on-exceed="exceed"
                :key="reRender"
                :limit="1"
                list-type="picture-card"
              >
                <i class="el-icon-plus" style="font-size: 18px"
                  >Selo Cerficado 2</i
                >
              </el-upload>
            </div>
          </vs-col>
          <vs-col vs-w="4" vs-type="flex" vs-align="center" vs-justify="center">
            <div class="my-10">
              <el-upload
                accept=" .jpg, .jpeg, .png, .gif"
                action=""
                :auto-upload="false"
                ref="selo_certificado_3"
                :on-change="changeListSelosCerficado"
                :file-list="fileListSeloCerficado3"
                :on-remove="removeSeloCerficado3"
                class="w-full"
                :on-exceed="exceed"
                :key="reRender"
                :limit="1"
                list-type="picture-card"
              >
                <i class="el-icon-plus" style="font-size: 18px"
                  >Selo Cerficado 3</i
                >
              </el-upload>
            </div>
          </vs-col>
          <vs-col vs-w="4" vs-type="flex" vs-align="center" vs-justify="center">
            <div class="my-10">
              <el-upload
                accept=" .jpg, .jpeg, .png, .gif"
                action=""
                :auto-upload="false"
                ref="selo_certificado_4"
                :on-change="changeListSelosCerficado"
                :file-list="fileListSeloCerficado4"
                :on-remove="removeSeloCerficado4"
                class="w-full"
                :on-exceed="exceed"
                :key="reRender"
                :limit="1"
                list-type="picture-card"
              >
                <i class="el-icon-plus" style="font-size: 18px"
                  >Selo Cerficado 4</i
                >
              </el-upload>
            </div>
          </vs-col>
        </vs-row>
        <vs-divider border-style="dotted" color="dark" class="font-bold"
          >Imagem do Background (Limite de 1 Background)</vs-divider
        >
        <vs-row vs-type="flex" vs-w="12" vs-justify="flex-start">
          <vs-col
            vs-w="12"
            vs-type="flex"
            vs-align="center"
            vs-justify="center"
          >
            <div class="my-10">
              <el-upload
                accept=" .jpg, .jpeg, .png, .gif"
                action=""
                :auto-upload="false"
                ref="background"
                :on-change="changeListBackground"
                :file-list="backgroundList"
                :on-remove="deleteBackground"
                class="w-full"
                :on-exceed="exceed"
                :key="reRender"
                :limit="1"
                list-type="picture-card"
              >
                <i class="el-icon-plus" style="font-size: 18px">Background</i>
              </el-upload>
            </div>
          </vs-col>
        </vs-row>
        <vs-divider border-style="dotted" color="dark" class="font-bold"
          >Assinatura do Engenheiro Químico (Limite de 1 Imagem)</vs-divider
        >
        <vs-row vs-type="flex" vs-w="12" vs-justify="flex-start">
          <vs-col
            vs-w="12"
            vs-type="flex"
            vs-align="center"
            vs-justify="center"
          >
            <div class="my-10">
              <el-upload
                accept=" .jpg, .jpeg, .png, .gif"
                action=""
                :auto-upload="false"
                ref="assinaturaEngQuim"
                :on-change="changeListAssinaturaEngQuim"
                :file-list="assinaturaEngQuimList"
                :on-remove="deleteAssinaturaEngQuim"
                class="w-full"
                :on-exceed="exceed"
                :key="reRender"
                :limit="1"
                list-type="picture-card"
              >
                <i class="el-icon-plus" style="font-size: 18px">Assinatura</i>
              </el-upload>
            </div>
          </vs-col>
        </vs-row>
      </div>
    </VuePerfectScrollbar>
  </vx-card>
</template>

<script>
import components from "@/components/default/exports.js";

export default {
  data() {
    return {
      email: [],
      parametro: {},
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.8
      },
      reRender: 0,
      blockButton: false,
      //variaveis dos selos
      file: {},
      dialogVisible: false,
      dialogImageUrl: null,
      changeSelos: false,
      fileListSelo1: [],
      fileListSelo2: [],
      fileListSelo3: [],
      fileListSelo4: [],
      //variaveis dos selos certificado
      changeSelosCerficado: false,
      fileListSeloCerficado1: [],
      fileListSeloCerficado2: [],
      fileListSeloCerficado3: [],
      fileListSeloCerficado4: [],
      //variaveis do background
      dialogVisibleBackground: false,
      dialogImageUrlBackground: null,
      backgroundList: [],
      changeBackground: false,
      //assinatura eng quim
      assinaturaEngQuimList: [],
      changeAssinaturaEngQuim: false,
    };
  },
  methods: {
    //Funcoes Globais
    async getParametros() {
      await this.$vs.loading({
        container: "#parametros",
        scale: 0.6
      });
      this.blockButton = true;
      try {
        this.parametro = await this.$http.get(`parametro/1`);
        await this.mountObjImageSelos();
        await this.mountObjImageSelosCerficado();
        await this.mountObjImageBackground();
        await this.mountObjAssinaturaEngQuim();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.blockButton = await false;
        this.reRender += await 1;
        await this.$vs.loading.close("#parametros > .con-vs-loading");
      }
    },
    async update() {
      await this.$vs.loading({
        container: "#parametros",
        scale: 0.6
      });
      this.blockButton = true;
      try {
        await this.$http.put(`parametro/1`, this.parametro);
        if (this.changeSelos) {
          await this.uploadImagensSelos();
        }
        if (this.changeSelosCerficado) {
          await this.uploadImagensSelosCerficado();
        }
        if (this.changeAssinaturaEngQuim) {
          await this.uploadAssinaturaEngQuim();
        }
        if (this.changeBackground) {
          await this.uploadImagemBackground();
        }
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "atualizar",
          setor: "comercial",
          ip: window.localStorage.getItem("ip"),
          texto: "Atualização dos parâmetros "
        };
        await this.$logger(logData);
        await this.success();
        this.reRender++;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.blockButton = false;
        await this.$vs.loading.close("#parametros > .con-vs-loading");
        await this.getParametros();
      }
    },
    async success() {
      this.$vs.notify({
        title: "Sucesso",
        text: "Parâmetros Atualizados com Sucesso!",
        color: "success"
      });
    },
    async exceed() {
      this.$vs.notify({
        title: "Tenha Calma!",
        text: "Limite de 1 Imagen!",
        color: "danger"
      });
    },
    //Funcoes da Assiantura do Eng Quimico
    async deleteAssinaturaEngQuim() {
      await this.$vs.loading({
        container: "#parametros",
        scale: 0.6
      });
      this.blockButton = true;
      try {
        this.parametro = await this.$http.delete(`deleteAssinaturaEngQuim`);
        await this.success();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close("#parametros > .con-vs-loading");
        await this.getParametros();
        this.reRender += await 1;
        this.blockButton = false;
      }
    },
    async mountObjAssinaturaEngQuim() {
      let file = null;
      file = this.parametro.assinatura_engenheiro_quimico;
      this.assinaturaEngQuimList = [];
      if (file) {
        this.assinaturaEngQuimList.push({
          name: file,
          url: `${this.parametro.storage_cdn_url}/${file}`,
          status: "success"
        });
        this.reRender++;
      }
    },
    async uploadAssinaturaEngQuim() {
      try {
        let file = this.$refs.assinaturaEngQuim.uploadFiles;
        let formData = new FormData();
        await formData.append("image", file[0].raw);
        await this.$http.post(`uploadAssinaturaEngQuim`, formData);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.changeAssinaturaEngQuim = false;
      }
    },
    //Funcoes dos Selos
    async uploadImagensSelos() {
      try {
        let file1 = await this.$refs.selo_1.uploadFiles;
        let file2 = await this.$refs.selo_2.uploadFiles;
        let file3 = await this.$refs.selo_3.uploadFiles;
        let file4 = await this.$refs.selo_4.uploadFiles;
        let formData = new FormData();
        if (file1.length > 0) {
          if (file1[0].raw != undefined) {
            await formData.append("image", file1[0].raw);
            await this.$http.post("uploadImagemSelo/1", formData);
          }
        }
        if (file2.length > 0) {
          if (file2[0].raw != undefined) {
            await formData.append("image", file2[0].raw);
            await this.$http.post("uploadImagemSelo/2", formData);
          }
        }
        if (file3.length > 0) {
          if (file3[0].raw != undefined) {
            await formData.append("image", file3[0].raw);
            await this.$http.post("uploadImagemSelo/3", formData);
          }
        }
        if (file4.length > 0) {
          if (file4[0].raw != undefined) {
            await formData.append("image", file4[0].raw);
            await this.$http.post("uploadImagemSelo/4", formData);
          }
        }
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.changeSelos = await false;
      }
    },
    async mountObjImageSelos() {
      this.fileListSelo1 = await [];
      this.fileListSelo2 = await [];
      this.fileListSelo3 = await [];
      this.fileListSelo4 = await [];
      let file1 = this.parametro[`selo_relatorio_tecnico1`];
      let file2 = this.parametro[`selo_relatorio_tecnico2`];
      let file3 = this.parametro[`selo_relatorio_tecnico3`];
      let file4 = this.parametro[`selo_relatorio_tecnico4`];
      if (file1) {
        this.fileListSelo1.push({
          name: file1,
          url: `${this.parametro.storage_cdn_url}/${file1}`,
          status: "success"
        });
      }
      if (file2) {
        this.fileListSelo2.push({
          name: file2,
          url: `${this.parametro.storage_cdn_url}/${file2}`,
          status: "success"
        });
      }
      if (file3) {
        this.fileListSelo3.push({
          name: file3,
          url: `${this.parametro.storage_cdn_url}/${file3}`,
          status: "success"
        });
      }
      if (file4) {
        this.fileListSelo4.push({
          name: file4,
          url: `${this.parametro.storage_cdn_url}/${file4}`,
          status: "success"
        });
      }
      this.reRender++;
    },
    async removeSelo1(file) {
      await this.deleteImagemSelo("selo_relatorio_tecnico1");
    },
    async removeSelo2(file) {
      await this.deleteImagemSelo("selo_relatorio_tecnico2");
    },
    async removeSelo3(file) {
      await this.deleteImagemSelo("selo_relatorio_tecnico3");
    },
    async removeSelo4(file) {
      await this.deleteImagemSelo("selo_relatorio_tecnico4");
    },
    async deleteImagemSelo(campo) {
      await this.$vs.loading({
        container: "#parametros",
        scale: 0.6
      });
      this.blockButton = true;
      try {
        await this.$http.post(`deleteImagemSelo`, { campo: campo });
        await this.success();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close("#parametros > .con-vs-loading");
        await this.getParametros();
        this.reRender += await 1;
        this.blockButton = false;
      }
    },
    //Funcoes dos Selos Certificado
    async uploadImagensSelosCerficado() {
      try {
        let file1 = await this.$refs.selo_certificado_1.uploadFiles;
        let file2 = await this.$refs.selo_certificado_2.uploadFiles;
        let file3 = await this.$refs.selo_certificado_3.uploadFiles;
        let file4 = await this.$refs.selo_certificado_4.uploadFiles;

        let formData = new FormData();
        if (file1.length > 0) {
          if (file1[0].raw != undefined) {
            await formData.append("image", file1[0].raw);
            await this.$http.post("uploadImagemSeloCertificado/1", formData);
          }
        }
        if (file2.length > 0) {
          if (file2[0].raw != undefined) {
            await formData.append("image", file2[0].raw);
            await this.$http.post("uploadImagemSeloCertificado/2", formData);
          }
        }
        if (file3.length > 0) {
          if (file3[0].raw != undefined) {
            await formData.append("image", file3[0].raw);
            await this.$http.post("uploadImagemSeloCertificado/3", formData);
          }
        }
        if (file4.length > 0) {
          if (file4[0].raw != undefined) {
            await formData.append("image", file4[0].raw);
            await this.$http.post("uploadImagemSeloCertificado/4", formData);
          }
        }
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.changeSelosCerficado = await false;
      }
    },
    async mountObjImageSelosCerficado() {
      this.fileListSeloCerficado1 = await [];
      this.fileListSeloCerficado2 = await [];
      this.fileListSeloCerficado3 = await [];
      this.fileListSeloCerficado4 = await [];
      let file1 = this.parametro[`selo_certificado1`];
      let file2 = this.parametro[`selo_certificado2`];
      let file3 = this.parametro[`selo_certificado3`];
      let file4 = this.parametro[`selo_certificado4`];
      if (file1) {
        this.fileListSeloCerficado1.push({
          name: file1,
          url: `${this.parametro.storage_cdn_url}/${file1}`,
          status: "success"
        });
      }
      if (file2) {
        this.fileListSeloCerficado2.push({
          name: file2,
          url: `${this.parametro.storage_cdn_url}/${file2}`,
          status: "success"
        });
      }
      if (file3) {
        this.fileListSeloCerficado3.push({
          name: file3,
          url: `${this.parametro.storage_cdn_url}/${file3}`,
          status: "success"
        });
      }
      if (file4) {
        this.fileListSeloCerficado4.push({
          name: file4,
          url: `${this.parametro.storage_cdn_url}/${file4}`,
          status: "success"
        });
      }
      this.reRender++;
    },
    async removeSeloCerficado1(file) {
      await this.deleteImagemSeloCertificado("selo_certificado1");
    },
    async removeSeloCerficado2(file) {
      await this.deleteImagemSeloCertificado("selo_certificado2");
    },
    async removeSeloCerficado3(file) {
      await this.deleteImagemSeloCertificado("selo_certificado3");
    },
    async removeSeloCerficado4(file) {
      await this.deleteImagemSeloCertificado("selo_certificado4");
    },
    async deleteImagemSeloCertificado(campo) {
      await this.$vs.loading({
        container: "#parametros",
        scale: 0.6
      });
      this.blockButton = true;
      try {
        await this.$http.post(`deleteImagemSelo`, { campo: campo });
        await this.success();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close("#parametros > .con-vs-loading");
        await this.getParametros();
        this.reRender += await 1;
        this.blockButton = false;
      }
    },

    //Funcoes Background
    async deleteBackground() {
      await this.$vs.loading({
        container: "#parametros",
        scale: 0.6
      });
      this.blockButton = true;
      try {
        await this.$http.delete(`deleteImageBackground`);
        await this.success();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close("#parametros > .con-vs-loading");
        await this.getParametros();
        this.reRender += await 1;
        this.blockButton = false;
      }
    },
    async mountObjImageBackground() {
      let file = null;
      file = this.parametro.imagem_background;
      this.backgroundList = [];
      if (file) {
        this.backgroundList.push({
          name: file,
          url: `${this.parametro.storage_cdn_url}/${file}`,
          status: "success"
        });
        this.reRender++;
      }
    },
    async uploadImagemBackground() {
      try {
        let file = this.$refs.background.uploadFiles;
        let formData = new FormData();
        await formData.append("image", file[0].raw);
        await this.$http.post(`uploadImagemBackground`, formData);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.changeBackground = false;
      }
    },
    //Change Events
    async changeListAssinaturaEngQuim() {
      this.changeAssinaturaEngQuim = true;
    },
    async changeListSelos() {
      this.changeSelos = true;
    },
    async changeListSelosCerficado() {
      this.changeSelosCerficado = true;
    },
    async changeListBackground() {
      this.changeBackground = true;
    }
  },
  components: {
    ...components
  },
  async mounted() {
    await this.getParametros();
  }
};
</script>

<style scoped lang="scss">
.scroll-parametros {
  max-height: 78vh !important;
}
</style>
